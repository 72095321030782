import styled from "styled-components";

import Borracha from '../../assets/products/borracha.png';
import Escorredor from '../../assets/products/ESCORREDOR.png';
import PortaRetangular from '../../assets/products/porta-retangular-480x340x120.png';
import PortaOval from '../../assets/products/porta-oval-250x300.png';
import ManipuloRosca from '../../assets/products/manipulo-rosca.png';
import PortaInspecao from '../../assets/products/porta-inspecao-modelo-460x340x90.png';
import PortaDn from '../../assets/products/porta-dn-330.png';
import Lavador from '../../assets/products/LAVADOR.png';
import BombaCentrifuga from '../../assets/products/bomba-centrifuga-monofasica.png'
import ChapaBaseTanques from '../../assets/products/chapa-base-tanques.png';
import Curva90 from '../../assets/products/curva-90.png';
import Reducao from '../../assets/products/reducao.png';
import SapataRegulavel from '../../assets/products/sapata-regulavel.png';
import TeeRetoPipe from '../../assets/products/tee-reto-pipe.png';
import UniaoSms from '../../assets/products/uniao-sms.png';
import UniaoTc from '../../assets/products/uniao-tc.png';
import ValvulaAliviada from '../../assets/products/valvula-aliviada.png';
import ValvulaMacica from '../../assets/products/valvula-macica.png';
import PortaBracoArticulado from '../../assets/products/porta-inspeção-braço-articulado.png';
import PortaRetangular308 from '../../assets/products/porta-retangular-308-208.png';
import PortaTampoVidro from '../../assets/products/porta-inspecao-tampo-vidro.png';

const startColor = "#A7A7A7";
const endColor = "#0F131F";

export function LinkProduct(obj) {
  let url = null;
  switch (obj) {
    case "borracha-vedacao-epdm":
      url = <img src={Borracha}></img>;
      break;
    case "valvula-macica":
      url = <img src={ValvulaMacica}></img>;
      break;
    case "valvula-aliviada":
      url = <img src={ValvulaAliviada}></img>;
      break;
    case "uniao-tc":
      url = <img src={UniaoTc}></img>;
      break;
    case "uniao-sms":
      url = <img src={UniaoSms}></img>;
      break;
    case "tee-reto":
      url = <img src={TeeRetoPipe}></img>;
      break;
    case "sapata-regulavel":
      url = <img src={SapataRegulavel}></img>;
      break;
    case "reducao":
      url = <img src={Reducao}></img>;
      break;
    case "curva-90":
      url = <img src={Curva90}></img>;
      break;
    case "chapa-base-tanque":
      url = <img src={ChapaBaseTanques}></img>;
      break;
    case "bomba-centrifuga-monofasica-mini-bomba":
      url = <img src={BombaCentrifuga}></img>;
      break;
    case "manipulo-rosca":
      url = <img src={ManipuloRosca}></img>;
      break
    case "escorredor-de-garrafas":
      url = <img src={Escorredor}></img>;
      break;
    case "lavador-garrafas":
      url = <img src={Lavador}></img>;
      break;
    case "porta-inspecao-oval-465-375":
      url = <img src={PortaBracoArticulado}></img>;
      break;
    case "porta-oval-300-250":
      url = <img src={PortaOval}></img>;
      break;
    case "porta-dn-330":
      url = <img src={PortaDn}></img>;
      break;
    case "porta-retangular-480-340-120":
      url = <img src={PortaRetangular}></img>;
      break;
    case "porta-inspeção-modelo-460x340x90":
      url = <img src={PortaInspecao}></img>;
      break;
    case "porta-retangular-308-208":
      url = <img src={PortaRetangular308}></img>;
      break;
      case "porta-inspeção-tampo-borossilicato":
        url = <img src={PortaTampoVidro}></img>;
        break;
    default:
      break;
  }
  return url;
}

export const StyledCelestialObject = styled.div`
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
`;