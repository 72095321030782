import { useRef, useState } from "react";
import { BurgerMenu } from "./BurgerMenu";
import { NavbarStyle, MobileView } from "./NavbarStyles";
import { Link } from "react-router-dom";
import './Navbar.css';

const Navbar = () => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const toggleBurger = () => {
    setBurgerOpen(!burgerOpen);
  };

  return (
    <div className="navbar-wrapper">
      <NavbarStyle>
        <Link to="/" onClick={toggleBurger}>
          Home
        </Link>
        <div className="dropdown" >
          <button className="dropbtn">
            Produtos
            <i className="fa fa-caret-down"></i>
          </button>
          <div className="dropdown-content">
            <Link to={`/products/Tampas`}><b>Tampas</b></Link>
            <Link to={`/products/Acessorios`}><b>Acessórios</b></Link>
          </div>
        </div>
        <Link to="/about" onClick={toggleBurger}>
          Sobre Nós
        </Link>
        <Link to="/contact" onClick={toggleBurger}>
          Contato
        </Link>
      </NavbarStyle>

      <MobileView>
        <div className="burgermenu-toggle" onClick={toggleBurger}>
          <BurgerMenu />
        </div>
      </MobileView>

      <style jsx>
        {`
          @media (max-width: 550px) {
            .navbar-wrapper > div > a {
              display: ${burgerOpen ? "inline" : "none"};
            }
          }
        `}
      </style>
    </div>
  );
};

export { Navbar }
