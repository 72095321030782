import styled from "styled-components";
import Capa3 from '../../assets/capa2.png';

export const AboutH1 = styled.h1`
  font-size: 4rem;
  margin-bottom: 1.90625em;
`;

export const AboutBody = styled.div`
  background-image: url(${Capa3})  !important;
  background-repeat:no-repeat;
  -webkit-background-size:cover;
  -moz-background-size:cover;
  -o-background-size:cover;
  background-size:cover;
  background-position:center;
`;

export const AboutP = styled.p`
  font-size: 1.8rem;
  margin-bottom: 1.2189999999999999em;
  max-width: 75ch;
  color: white;
`;

export const AboutSection = styled.section`
  margin-bottom: 1.1em;
  height: 48em;
  maring-top: 0;
  padding: 1.8em;
`;
