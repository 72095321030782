import styled from "styled-components";

const NavbarStyle = styled.div`
  display: flex;
  // justify-content: flex-end;

  > a {
    margin-top: 1.9vh;
    margin-left: 1.9vw;
    margin-right: 2.1vw;
    font-weight: normal;
    font-size: larger;
    text-decoration: none;

    @media (max-width: 550px) {
      font-size: 1.8rem;
    }
  }

  @media (max-width> 550px) {
    > a {
      font-size: revert;
    }
  }

  > a:hover {
    color: #96bcb7;
  }

  > a:active {
    color: blue;
  }
`;

const MobileView = styled.div`
  .burgermenu-toggle {
    display: none;
  }

  @media (max-width: 550px) {
    .burgermenu-toggle {
      display: fixed;
      padding-top: 10px;
      margin-left: 10px;
      font-weight: normal;
      font-size: medium;
    }
  }
`;

export { NavbarStyle, MobileView };
