import {
  StyledSection,
  StyledHeading,
  StyledPlanet,
  StyleImg,
  StyleSpan
} from "./StyledMarsPromo";

import PortaRetangular from '../../assets/products/porta-retangular-prt-308x208.png';
import PortaInspecao from '../../assets/products/porta-inspecao-modelo-460x340x90.png';
import PortaDn from '../../assets/products/porta-dn-330.png';
import Porta480 from '../../assets/products/porta-retangular-480x340x120.png';
import InspecaoBracoArticulado from '../../assets/products/porta-inspeção-braço-articulado.png';
import InspecaoTampoVidro from '../../assets/products/porta-inspecao-tampo-vidro.png';
import { Link } from "react-router-dom";

const MarsPromo = () => (
  <StyledSection>
    <StyledHeading>Nossos Produtos</StyledHeading>
    <StyledPlanet>
      <Link to={'product/porta-retangular-480-340-120'}>
        <StyleImg src={Porta480} ></StyleImg>
        <StyleSpan>Porta de Inspeção Retangular 480x340</StyleSpan>
      </Link>
    </StyledPlanet>
    <StyledPlanet>
      <Link to={'product/porta-retangular-308-208'}>
        <StyleImg src={PortaRetangular} ></StyleImg>
        <StyleSpan>Porta de Inspeção Retangular 308×208</StyleSpan>
      </Link>
    </StyledPlanet>
    <StyledPlanet>
      <Link to={'product/porta-inspeção-modelo-460x340x90'}>
        <StyleImg src={PortaInspecao} ></StyleImg>
        <StyleSpan>Porta de inspeção modelo 460x340x90</StyleSpan>
      </Link>
    </StyledPlanet>
    <StyledPlanet>
      <Link to={'product/porta-inspecao-oval-465-375'}>
        <StyleImg src={InspecaoBracoArticulado} ></StyleImg>
        <StyleSpan>Porta de Inspeção Oval 465 x 375</StyleSpan>
      </Link>
    </StyledPlanet>
    <StyledPlanet>
      <Link to={'product/porta-inspeção-tampo-borossilicato'}>
        <StyleImg src={InspecaoTampoVidro} ></StyleImg>
        <StyleSpan>Porta de Inspeção Com Tampo de Borossilicato</StyleSpan>
      </Link>
    </StyledPlanet>
    <StyledPlanet>
      <Link to={'product/porta-dn-330'}>
        <StyleImg src={PortaDn} ></StyleImg>
        <StyleSpan>Porta de Inspeção DN 330</StyleSpan>
      </Link>
    </StyledPlanet>
  </StyledSection>
);

export default MarsPromo;