import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { produtos } from "../../constants";

import PageHeader from "../../components/PageHeader/PageHeader";
import {
    StyledPageHeaderLoadingBox,
    StyledCelestialObjectLoadingBox,
    StyledCelestialObjectSection,
    StyledInformationLoadingBox,
    StyledCelestialObject,
    StyledCelestialObjectInformation,
} from "./StyledProducts";
import { LinkProduct } from "../../components/CelestialObject/StyledCelestialObject";

const ProductPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const params = useParams();

    const fetchAndSetData = async () => {
        setIsLoading(true);

        try {
            const data = produtos.reduce(function (status, obj) {
                if (params.id === obj.id) {
                    status = obj;
                }
                return status;
            }, []);
            setData(data);

        } catch (err) {
            setData(null);
            console.error(err);

        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchAndSetData();

        // Cleanup
        return () => {
            setIsLoading(true);
            setData(null);
        }
    }, [params.id])

    if (isLoading) return (
        <>
            <PageHeader>
                <StyledPageHeaderLoadingBox />
            </PageHeader>

            <StyledCelestialObjectSection>
                <StyledCelestialObjectLoadingBox />
                <StyledInformationLoadingBox />
            </StyledCelestialObjectSection>
        </>
    )

    if (!data) return (
        <>
            <PageHeader title="Error" />
            <article>
                <p>Produto não localizado "{params.id}".</p>
            </article>
        </>
    )

    return (
        <>
            <PageHeader title={data.name} />
            <StyledCelestialObjectSection>
                <StyledCelestialObjectInformation data={data} />
                <StyledCelestialObject color={data.color} product={data.id}> {LinkProduct(data.id)} </StyledCelestialObject>
            </StyledCelestialObjectSection>
        </>
    )
}

export default ProductPage;