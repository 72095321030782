import {
  StyledSection,
  StyledHeading,
  StyledPlanet
} from "./StyledPromo";
import AllBot from '../../assets/partners/allbot.png';
import Beer from '../../assets/partners/beer.png';
import BeerB from '../../assets/partners/beerb.png';
import Cgl from '../../assets/partners/cgl.png';
import Inotess from '../../assets/partners/inotess.png';
import Ja from '../../assets/partners/ja.png';
import JetInox from '../../assets/partners/jetinox.png';
import Mirainox from '../../assets/partners/mirainox.png';
import Palenox from '../../assets/partners/palenox.png';
import Riff from '../../assets/partners/riffData.png';
import Santa from '../../assets/partners/santa.png';
import SudesteInox from '../../assets/partners/sudesteinox.png';


const PromoSection = () => (
  <StyledSection>
    <StyledHeading>Clientes e Parceiros</StyledHeading>
    <br />
    <StyledPlanet>
      <img src={Beer} width="151px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={AllBot} width="151px" height="151px" />
    </StyledPlanet>
    <StyledPlanet>
      <img src={BeerB} width="90px" height="151px" />
    </StyledPlanet>
    <StyledPlanet>
      <img src={Cgl} width="151px" height="151px"  />
    </StyledPlanet>
    <StyledPlanet>
      <img src={Inotess} width="151px" height="151px" />
    </StyledPlanet>
    <StyledPlanet>
      <img src={Ja} width="120px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={JetInox} width="151px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={Mirainox} width="151px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={Palenox} width="151px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={Riff} width="151px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={Santa} width="151px" height="151px"/>
    </StyledPlanet>
    <StyledPlanet>
      <img src={SudesteInox} width="151px" height="151px"/>
    </StyledPlanet>
  </StyledSection>
)

export default PromoSection;