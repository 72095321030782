import {
  StyledButton,
  StyledContentContainer,
  StyledHeading,
  StyledParagraph,
  StyledSection
} from "./StyledHero";

const HeroSection = () => (
  <StyledSection>
    <StyledContentContainer>
      <StyledParagraph>Kolber Soluções em Inox</StyledParagraph>
      <StyledHeading>Especialista na Fabricação de Portas de Inspeção.</StyledHeading>
      <StyledButton to="/products/Todos">Conheça nossos produtos!</StyledButton>      <StyledButton to='/contact'>Entre em contato</StyledButton>
    </StyledContentContainer>
  </StyledSection>
);

export default HeroSection;