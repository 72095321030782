import {
  StyledInformationContainer,
  StyledHeading
} from "./StyledCelestialObjectInformation";

const CelestialObjectInformation = ({ className, data }) => (
  <StyledInformationContainer className={className}>
    <StyledHeading>Detalhes do Produto</StyledHeading>
    <div dangerouslySetInnerHTML={{ __html: data.description }} />
  </StyledInformationContainer>
);

export default CelestialObjectInformation;