import styled from "styled-components";
import FundoProduto from '../../assets/fundo-produto.png';

export const StyledHeader = styled.header`
  color : white;
  height: 350px;
  display: flex;
  align-items: left;
  margin-left: 30px;
  flex-direction: column;
  margin-bottom: 10px;

  h1,
  p {
    margin: 0;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    margin-top: 100px;
  }

  p {
    margin-block-start: .8em;
  }

  @media (min-width: 48em) {
    // margin-block: 6em 4em;
    h1 {
      font-size: 50px;
    }
    height: 400px;
    display: flex;
    align-items: left;
    margin-left: 30px;
    flex-direction: column;
  }
`;

export const StyledHeaderBackgroundImage = styled.div`
  background-image: url(${FundoProduto});
  background-size:cover;
`;