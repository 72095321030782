import React from 'react';
import {
  Border,
  FooterA,
  FooterLink,
  FooterP,
  HeaderFooterP,
  StyledFooterColumn,
  StyledFooterRow,
  StyledFooterSection,
  StyledFooterMedias,
  StyledFooterMidSection,
  StyledFooterForm,
} from "./StyledFooter";
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/instagram.svg';
import Whatsapp from '../../assets/whatsapp.svg';
import Linkedin from '../../assets/linkedin.svg';
import Logo from '../../assets/Logo.svg'

const Footer = () => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Lógica para lidar com o envio do formulário aqui
  };

  return (
    <StyledFooterSection>
      <StyledFooterMidSection>
        <StyledFooterColumn>
          <FooterP>
            <img src={Logo} width="250" height="250" alt="Kolber Inox" id="logo" data-height-percentage="50" data-actual-width="540" data-actual-height="106" />
          </FooterP>
        </StyledFooterColumn>
        <StyledFooterColumn>
          <HeaderFooterP smallHeader>Midias Sociais</HeaderFooterP>
          <StyledFooterMedias>
            <div>
              <a href="https://www.facebook.com/kolbersolucoeseminox/" target="_blank" style={{ marginRight: '10px' }}><img alt="Facebook" src={Facebook} width='32px' height='32px' ></img></a>
              <a href="https://wa.me/5554984283031" target="_blank" style={{ marginRight: '10px' }}><img alt="Whatsapp" src={Whatsapp} width='32px' height='32px' ></img></a>
              <a href="https://www.linkedin.com/company/kolber-soluções-em-inox/" target="_blank" style={{ marginRight: '10px' }}><img alt="Linkedin" src={Linkedin} width='32px' height='32px' ></img></a>
              <a href="https://www.instagram.com/kolberinox/" target="_blank" style={{ marginRight: '10px' }}><img alt="Instagram" src={Instagram} width='32px' height='32px'></img></a>
            </div>
          </StyledFooterMedias>
        </StyledFooterColumn>
        <StyledFooterRow margin>
          <StyledFooterRow>
            <StyledFooterRow linkContainer>
              <StyledFooterColumn>
                <HeaderFooterP smallHeader>Entre em contato</HeaderFooterP>
                <FooterA href="https://www.google.com/maps/place/R.+Ernesto+Gasperin,+555+-+Maria+Goretti,+Bento+Gon%C3%A7alves+-+RS,+95700-000/@-29.1571272,-51.5306043,17z/data=!3m1!4b1!4m6!3m5!1s0x951c3b5bfefc8fef:0x970d029922e541a9!8m2!3d-29.1571272!4d-51.5306043!16s%2Fg%2F11gf1q204b?entry=ttu" target="_blank">Endereço: Rua Ernesto Gasperin,555,</FooterA>
                <FooterA href="https://www.google.com/maps/place/R.+Ernesto+Gasperin,+555+-+Maria+Goretti,+Bento+Gon%C3%A7alves+-+RS,+95700-000/@-29.1571272,-51.5306043,17z/data=!3m1!4b1!4m6!3m5!1s0x951c3b5bfefc8fef:0x970d029922e541a9!8m2!3d-29.1571272!4d-51.5306043!16s%2Fg%2F11gf1q204b?entry=ttu" target="_blank">Maria Goretti, Bento Gonçalves - RS</FooterA>
              </StyledFooterColumn>
              <StyledFooterColumn>
                <HeaderFooterP smallHeader>Fone: (54) 3452-2194</HeaderFooterP>
                <HeaderFooterP smallHeader>Vendas</HeaderFooterP>
                <FooterA href="#">Whatsapp:(54) 98428-3031</FooterA>                
                <FooterA>E-mail: vendas@kolberinox.com.br</FooterA>
                <HeaderFooterP smallHeader>Compras</HeaderFooterP>
                <FooterA>E-mail: contato@kolberinox.com.br</FooterA>
              </StyledFooterColumn>
            </StyledFooterRow>
          </StyledFooterRow>
        </StyledFooterRow>
      </StyledFooterMidSection>
      <StyledFooterForm>
      </StyledFooterForm>
      <Border />
    </StyledFooterSection>
  );
};

export default Footer;
