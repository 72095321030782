import styled from "styled-components";

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  padding-block: 1em;
  background-color: #0c35e6;
  opacity: 10;
  @media (max-width: 550px) {
    height: 100px;
  }

  > a {
    margin-top: 1vh;
    font-weight: bold;
    font-size: large;
    text-decoration: none;
  }

  @media (max-width: 550px) {
    .navbar-wrapper {
      background-color: none;
      font-size: 40px;
      height: 10vh;
      width: 40vw;
      display: flex;
      justify-content: flex-end;
    }

    .navbar-wrapper > div {
      flex-direction: column;
      justify-content: space-between;
    }
  }
`;

export { HeaderStyle };
