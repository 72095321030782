import styled from "styled-components";

import CelestialObject from "../CelestialObject/CelestialObject";

export const StyledSection = styled.section`
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-block: 4em;
  align-items: center;  
  aspect-radio: 3/2;
  object-fit: contain;

  @media (min-width: 48em) {
    display: grid;
    grid-template-columns: repeat(3, minmax(5em, 100%));
    gap: 2em;
    align-items: start;
    justify-content: space-between;
  }
`;

export const StyledHeading = styled.h1`
  text-align: left;
  color: black;
  @media (min-width: 48em) {
    font-size: 3rem;
    grid-column: 1 / 4;
  }
`;

export const StyledPlanet = styled.div`
display: flex;
align-items: center;
flex-direction: column;
gap: 1em;
border: 0;
padding: 0;
vertical-align: baseline;
background: transparent;

@media (min-width: 48em) {
  flex-direction: column;
  text-align: center;
  gap: 0.1em;
}
`;

export const StyleImg = styled.img`
  width: 250px; /* Defina a largura desejada para os cards */
  height: 250px;
  min-width: 120px;
  margin: 8px;
  margin-left: 2.4em;
`;

export const StyleSpan = styled.h2`
  color: black;
  text-decoration: none;
  line-height: 1.4em!important;
  font-weight: 700!important;
  font-size: 16px!important;
  text-align: center!important;
`;

export const StyledCelestialObject = styled(CelestialObject)`
  max-width: 10em;
`;

export const StyledPlanetParagraph = styled.p`
  margin: 0;
  flex-grow: 1;
  color: black;
`;