import PageHeaderContactAbout from "../../components/ContactAbout/ConctactAbout";
import emailjs from '@emailjs/browser';
import { EmailJsConfig } from "../../constants";

import {
  StyledForm,
  StyledLabel,
  StyledInput,
  StyledTextArea,
  StyledSubmitButton
} from "./StyledContact";

const ContactPage = () => {
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (e.target.name.value == '' || e.target.email.value == '' || e.target.message.value == '') {
      alert("Todos os campos são obrigatórios!");
      return;
    }

    const templateParams = {
      from_name: e.target.name.value,
      message: e.target.message.value,
      from_email: e.target.email.value
    };

    emailjs.send(EmailJsConfig.serviceId, EmailJsConfig.templateId, templateParams, EmailJsConfig.publicKey)
      .then((resolve) => {
        console.log("Enviado: ", resolve.status, resolve.text);
        alert('Contato enviado com sucesso!');
      }).catch((err) => {
        console.log("Error: ", err);
      });
    e.target.reset();
  }

  return (
    <>
      <PageHeaderContactAbout title="Contato" />
      <section>
        <StyledForm onSubmit={handleFormSubmit}>
          <StyledLabel>
            Nome:
            <StyledInput type="text " name="name" required />
          </StyledLabel>

          <StyledLabel>
            E-mail:
            <StyledInput type="email" name="email" required />
          </StyledLabel>

          <StyledLabel>
            Mensagem:
            <StyledTextArea name="message" required />
          </StyledLabel>

          <StyledSubmitButton type="submit" value="Enviar" />
        </StyledForm>
      </section>
    </>
  );
}

export default ContactPage;