import PageHeader from "../../components/PageHeader/PageHeader";
import { StyledSection } from "./StyledNotFound";

const NotFoundPage = () => (
  <>
    <PageHeader title="Não encontrado"/>
    <StyledSection>
      <article>
        <p>Desculpe, a página que você acessou não existe.</p>
      </article>
    </StyledSection>
  </>
);

export default NotFoundPage;