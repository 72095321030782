import { LinkProduct } from "../CelestialObject/StyledCelestialObject";
import { Link } from "react-router-dom";
import {
  StyledCard,
  StyledCelestialObject,
  StyledDescription,
  StyledButton,
  StyledLink
} from "./StyledCelestialObjectCard";

const CelestialObjectCard = ({
  className,
  color,
  name,
  to,
  id
}) => (
  <StyledLink href={`#` + to}>
    <StyledCard className={className}>
      <StyledCelestialObject color={color}>
        {LinkProduct(id)}
      </StyledCelestialObject>
      <StyledButton to={to || '/'}>{name}</StyledButton>
    </StyledCard>
  </StyledLink>
);

export default CelestialObjectCard;