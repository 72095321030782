import PageHeaderContactAbout from "../../components/ContactAbout/ConctactAbout";

import { AboutBody, AboutP, AboutSection } from "./StyledAbout";

const AboutPage = () => {
  return (
    <AboutBody>
      <AboutSection>
        <PageHeaderContactAbout title="Sobre" className={`about`} />
        <article>
          <AboutP>
            Fundada em 2017, no município de Bento Gonçalves, a Kolber Inox tem como seu principal objetivo buscar soluções em projetos padrões e especiais na fabricação de tampas de inspeção para tanques de armazenagem, nos tornando especialistas nessa área.
          </AboutP>
        </article>
      </AboutSection>
    </AboutBody>
  );
};

export default AboutPage;