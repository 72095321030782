export const endpoints = {
  bodies: 'https://api.le-systeme-solaire.net/rest/bodies'
}

export const colors = {
  mercure: '#FFBFAB',
  venus: '#E7F6A7',
  terre: '#1E84FD',
  mars: '#FD531E',
  jupiter: '#FF9E44',
  saturne: '#F5D86D',
  uranus: '#CBFFF3',
  neptune: '#2CD8FE',
  moon: '#A7A7A7'
}

export const EmailJsConfig = {
  serviceId: 'service_39h2mjs',
  templateId: 'template_mh5krrp',
  publicKey: 'ulsd47n1QzbMMKHMf'
}

export const produtos = [
  {
    id: 'porta-inspecao-oval-465-375',
    name: 'Porta de Inspeção Oval 465 x 375',
    description: '<p> • Sendo total inox 304 l chapa #6.0 mm</p><p> • Acabamento sanitário polido</p><p> • Vedação em EPDM podendo ser usada em temperaturas de -60° até +200°</p><p> • Manípulo rosca ¾”</p><p> • Capacidade de trabalho até 2.5 kgf</p><p> • Dimensões 465x375x150</p><p> • Pode ser adicionado o braço articulado a porta</p><p><b>Aplicação</b></p><p>Ideal para tanques de pressão e armazenagem de líquidos como vinícolas, destilarias, cervejaria dentre outros seguimentos.</p>',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'porta-oval-300-250',
    name: 'Porta de Inspeção Oval 300 x 250',
    description: '<p><p> • Sendo total inox 304 l chapa #5.0 mm</p><p><p> • Acabamento sanitário</p><p><p> • Vedação em EPDM</p><p><p> • Capacidade de trabalho até 2.0 kgf</p><p><p> • Dimensões 300×250</p><p><b>Aplicação</b></p><p>Ideal para tanques de pressão e armazenagem de líquidos como vinícolas, destilarias, cervejaria dentre outros seguimentos.</p>',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'porta-dn-330',
    name: 'Porta de Inspeção DN 330',
    description: '<p>Total inox 304</p><p>Diâmetro 330 mm</p><p>Vedação em EPDM atóxica podendo ser utilizada em temperaturas de -60° a 200°</p><p>Pressão trabalho 0.5 kgf</p><p><b>Aplicação</b></p><p>Ideal para uso superior em tanques de armazenagem como vinícola, destilaria, água dentre outros segmentos.</p>',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'porta-retangular-480-340-120',
    name: 'Porta de Inspeção Retangular 480 x 340',
    description: '<p> • Total inox 304 <p> • Vedação em EPDM <p> • Dimensão 480x340x60 ou 480x340x120</p><p> <b>Aplicação</b> </p> Ideal para tanques de pressão e armazenagem de líquidos como vinícolas, destilarias, cervejaria dentre outros seguimentos.',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'porta-retangular-308-208',
    name: 'Porta de Inspeção Retangular 308 x 208',
    description: '<p> • Total inox 304 <p> • Acabamento sanitário <p> • Vedação em EPDM <p> • Dimensão de 308x208x60 ou 308x208x120</p><p><b>Aplicação</b> <p></p> Ideal para tanques de pressão e armazenagem de líquidos como vinícolas, destilarias, cervejaria dentre outros seguimentos.',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'porta-inspeção-modelo-460x340x90',
    name: 'Porta de Inspeção Modelo 460 x 340',
    description: '<p>Totalmente em Inox 304l <p>- Tampo abaulado #2.5 mm <p>- Vedação em EPDM capacidade de uso -60° a 180° <p>- Passagem livre 450 x 330 mm <p>- Acabamento escovado</p><p><b>Aplicação</b> <p>Ideal para tanques de armazenagem de líquidos como vinícolas, destilarias, cervejaria e dentre outros seguimentos.</p>',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'porta-inspeção-tampo-borossilicato',
    name: 'Porta de Inspeção Com Tampo de Borossilicato',
    description: '<p>• Sendo tampa em total inox 304 l chapa #6.0mm.</p><p>• Vedação em EPDM podendo ser usada em temperaturas de -60° até +200°.</p><p>• Pescoço chapa inox 304 l #2.0mm diâmetro 440mm 120mm altura.</p><p>• Vidro com tratamento térmico alta resistência com #10.0 mm espessura.</p><p>Observação:No vidro pode ser jateado a logomarca do cliente.</p><p> <b>Aplicação</b> </p> Ideal para tanques de armazenagem de líquidos como vinícolas, destilarias, cervejaria dentre outros seguimentos. Suporta somente pressão ATM.</p>',
    group: 'Tampas',
    url: ''
  },
  {
    id: 'borracha-vedacao-epdm',
    name: 'Borracha de Vedação em EPDM',
    description: '<ul><li>Modelo de 370 mm externo</li><li>Modelo 245 externo</li><li>Pode ser usado em temperaturas de – 60º até + 200º</li></ul>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'valvula-macica',
    name: 'Válvula Borboleta Sanitária Maciça Inox',
    description: '<p> • Totalmente em aço inoxidável, ideal para aplicações que requeiram resistência e sanitariedade.</p><p> • A <strong>Válvula borboleta modelo VB (Maciça) Esferaval</strong> vem se destacando sobre outros tipos de válvulas pela sua sanitariedade absoluta, segurança, facilidade de instalação e operação (fecho rápido), perfeita estanquiedade (classe de pressão 10BAR), baixa perda de carga, fácil desmontagem para realizar a limpeza, entre outras características.</p><p> • A instalação na tubulação pode ser feita através de terminais (uniões) ou por solda de topo.</p><p>São fornecidas nas bitolas de 1", 1.1/2", 2", 2.1/2", 3", 4" e 6".</p><ul><li>com trava, cadeado - opcional</li><li>cor dos cabos: preto, azul ou verde - opcional</li><li>vedações: silicone, epdm, viton - opcional</li></ul>',
    group: 'Acessorios',
    url: '',
  },
  {
    id: 'valvula-aliviada',
    name: 'Válvula Aliviada',
    description: '<p>• Aliviada inox 304 cabo catraca</p><p>• A Válvula aliviada dispomos nas polegadas de 1" a 3".</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'uniao-tc',
    name: 'União TC',
    description: '<p> • União TC Trl Clamp Inox Solda OD</p><p> • União Clamp ou TC - Norma ISO 2852 -- OD (Solda)</p><p> • Composta por uma Abraçadeira clamp, dois niples clamp curtolongo e um anel de vedação clamp.</p><p> • Bitolas 1/2", 3/4", 1", 1.1/4", 1.1/2", 2", 2.1/2", 3", 4", 5", 6".</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'uniao-sms',
    name: 'União SMS',
    description: '<p>• União SMS Completa Inox Solda OD</p><p> • União SMS - Norma SMS 1145 -- OD (Solda)</p><p> • Composta por uma porca SMS ranhurada, um niple SMS , um macho SMS e um anel de vedação SMS.</p><p> • Bitolas: 1/2", 3/4", 1", 1.1/4", 1.1/2", 2", 2.1/2", 3", 4", 5", 6".</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'tee-reto',
    name: 'Tee Reto',
    description: '<p> • Tee Reto Pipe Inox Solda OD</p><p> • PI / PE (Polido interna ou externa) ou decapado.</p><p> <b>Aplicação</b> </p> Permite diversas alternativas de fluxo.</p><p>Especificação: 1/2" (12,7 mm) à 6" (152,4 mm)</p><p>Conexão: Extremidade solda ou conectores já soldados.</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'sapata-regulavel',
    name: 'Sapata Regulavel',
    description: '<p> • Sapata utilizada para nivelar os tanques.</p><p> • Dispomos de 2" à 6".</p><p> • Em inox 304 ou 316.</p><p> • Maior praticidade e durabilidade.</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'reducao',
    name: 'Redução Concêntrica Pipe Inox Solda OD',
    description: '<p> • Redução Concêntrica Pipe Inox Solda OD</p><p> • Em inox 304 ou inox 316.</p><p> • Dispomos diâmetro mínimo de 1/4" e máximo 6".</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'curva-90',
    name: 'Curva 90',
    description: '<p> • Curva 90º Pipe Inox Solda OD</p><p> • PI / PE (Polida interna ou externa) ou decapada.</p><p> <b>Aplicação</b> </p> Altera a direção do fluxo em movimento.</p><p>Especificação: 3/8" (9,52 mm) à 6" (152,4 mm)</p><p>Conexão Extremidade solda ou conectores já soldados.</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'chapa-base-tanque',
    name: 'Chapa Base para Tanques',
    description: '<p> • Sendo total inox 304</p> <p> • Diâmetro externo 130 mm</p> <p> • Chapa #2.0</p> <p><b>Aplicação</b></p> <p>Uso indicado para nivelamento de máquinas e tanques protegendo de ter contato direto com o piso.</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'bomba-centrifuga-monofasica-mini-bomba',
    name: 'Bomba Centrífuga Monofásica',
    description: '<p> • Bomba Centrífuga Monofásica em inox 304, ideal para micro cervejeiros ou pequenos produtores.</p> <p>Disponível nas seguintes configurações:</p> <ul> <li>Bomba sanitária alimentícia em aço inox AISI 304</li> <li>Tensão de 110V ou 220V monofásico (com chave para troca de voltagem)</li> </ul> <p>Potência</p> <ul> <li>½ CV</li> <li>1 CV</li> </ul> <p>Conexão</p> <ul> <li>SMS, DIN, TC , RJT ou adaptador para mangueira (Espigão)</li> <li>Rosca BSP ou NPT</li> </ul> <p>Temperatura: -20° à 150°.</p> <p>Tubulações até 1"</p>',
    group: 'Acessorios',
    url: ''
  },
  {
    id: 'manipulo-rosca',
    name: 'Manipulo Rosca',
    description: '<p> • Sendo total inox 304 <p/> <p> • Acabamento polido <p /> <p> • Rosca ¾” em latão</p>',
    group: 'Acessorios',
    url: ''
  }
]