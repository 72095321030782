import { produtos } from "../../constants";
import { useParams } from "react-router-dom";

import PageHeaderContactAbout from "../../components/ContactAbout/ConctactAbout";
import CelestialObjectCardsContainer from "../../components/CelestialObjectCardsContainer/CelestialObjectCardsContainer";
import CelestialObjectCard from "../../components/CelestialObjectCard/CelestialObjectCard";

import { StyledSection } from "./StyledPlanets";

const Planets = () => {
  const params = useParams();

  return (
    <>
      <PageHeaderContactAbout title="Nossos Produtos" />
      <StyledSection>
        <CelestialObjectCardsContainer>
          {produtos.map((produto) => {
            if (params.gr === "Todos" || produto.group === params.gr) {
              return (
                <CelestialObjectCard
                  key={produto.id}
                  color={produto.color}
                  name={produto.name}
                  id={produto.id}
                  to={`/product/${produto.id}`}
                />
              );
            }})}
        </CelestialObjectCardsContainer>
      </StyledSection>
    </>
  );
}
export default Planets;