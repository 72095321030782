// import React from "react";
import { Link } from "react-router-dom";
import { Navbar } from "./Navbar";
import { HeaderStyle } from "./HeaderStyles";
import { elementHider, HeaderHider } from "./elementHider";
import Logo from '../../assets/Logo.svg'
import { useEffect, useState } from "react";

/** hiding/showing the top menu when scrolling  */
window.addEventListener("scroll", elementHider);

const Header = () => {

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <HeaderHider>
      <header className={`header-wrapper`}>
        <HeaderStyle>
          <Link to="/">
            <img src={Logo} width="200" height="200" alt="Kolber Inox" id="logo" data-height-percentage="50" data-actual-width="540" data-actual-height="106" />
          </Link>
          <Navbar />
        </HeaderStyle>
      </header>
    </HeaderHider>
  );
};

export { Header };
