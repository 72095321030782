import { StyledHeader, StyledHeaderBackgroundImage } from "./StyledPageHeader";

const PageHeader = ({ className, title, description, children }) => (
  <StyledHeaderBackgroundImage>
    <StyledHeader className={className}>
    <h2>Produto</h2>
      {title && <h1>{title}</h1>}
      {description && <p>{description}</p>}
      {children}
    </StyledHeader>
  </StyledHeaderBackgroundImage>
);

export default PageHeader;