import { StyledHeaderContactAbout } from "./ContactAboutStyled";

const PageHeaderContactAbout = ({ className, title, description, children }) => (
  <StyledHeaderContactAbout className={className}>
    {title && <h1>{title}</h1>}
    {description && <p>{description}</p>}
    {children}
  </StyledHeaderContactAbout>
);

export default PageHeaderContactAbout;